<template>
  <div>
    <div uk-grid>
      <div class="uk-width-2-3">
        <div class="uk-text-large">Checkout</div>
        <UserQuotables
          :userQuotables="userQuotables"
          :isLoading="isLoading"
          :editable="false"
          :hideSavedForLater="true"
        />
      </div>
      <div class="uk-width-1-3">
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div class="uk-text-large">Selecciona un método de entrega</div>
          <router-link
            class="uk-button uk-button-primary uk-border-rounded"
            to="/profile"
          >
            Administrar direcciones
          </router-link>
        </div>
        <div>
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            <label
              ><input
                class="uk-radio"
                type="radio"
                name="shipping-addresses"
                checked
                v-model="multipleShippingAddresses"
                :value="false"
              />
              Entrega en una dirección</label
            >
            <label
              ><input
                class="uk-radio"
                type="radio"
                name="shipping-addresses"
                v-model="multipleShippingAddresses"
                :value="true"
              />
              Entrega en múltiples direcciones</label
            >
          </div>
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <div uk-grid class="uk-margin-top">
            <div
              v-for="address in addresses"
              :key="address.id"
              class="uk-width-1-1"
            >
              <div
                class="
                  uk-card uk-card-default uk-padding-small uk-border-rounded
                "
              >
                <label>
                  <div class="uk-flex uk-flex-between">
                    <div class="uk-text-bold uk-text-large">
                      <input
                        v-if="multipleShippingAddresses"
                        class="uk-checkbox"
                        type="checkbox"
                        v-model="model.addresses[address.id].selected"
                      />
                      <input
                        v-else
                        class="uk-radio"
                        type="radio"
                        name="address"
                        v-model="mainAddressId"
                        :value="address.id"
                      />
                      <span class="uk-margin-small-left"
                        >{{ address.first_name }} {{ address.last_name }}</span
                      >
                    </div>

                    <div
                      v-if="address.id in shippingLabels"
                      class="
                        uk-margin-right uk-link-reset uk-flex uk-flex-middle
                      "
                    >
                      <div v-if="pointsIcon">
                        <img :src="pointsIcon" alt="" width="50px" />
                      </div>
                      <div class="uk-margin-small-left">
                        {{ formatThousands(shippingLabels[address.id].quote) }}
                        {{ pointsName }}
                      </div>
                    </div>
                  </div>
                  <div>
                    <span class="uk-text-bold">Teléfono:</span>
                    {{ address.phone }}
                  </div>
                  <div>
                    <span class="uk-text-bold">Dirección:</span>
                    {{ address.address }}, Zona
                    {{ address.zone }}
                  </div>
                  <div>
                    {{ address.city.name }}, {{ address.city.state.name }},
                    {{ address.city.state.country.spanish }}
                  </div>

                  <div
                    class="uk-margin-top uk-text-bold"
                    v-if="
                      multipleShippingAddresses &&
                      model.addresses[address.id].selected
                    "
                  >
                    <div>Distribución de items</div>

                    <div
                      v-for="(userQuotable, index) in userQuotables"
                      :key="
                        'address-' + address.id + '-userQuotableIndex-' + index
                      "
                      class="uk-margin-small-bottom uk-flex uk-flex-middle"
                    >
                      <input
                        class="
                          uk-width-small
                          uk-input
                          uk-text-center
                          uk-border-rounded
                          background-white
                          uk-margin-small-right
                        "
                        type="number"
                        v-model.number="
                          model.addresses[address.id].redeemables[
                            userQuotable.id
                          ]
                        "
                      />
                      <div
                        :class="{
                          'uk-text-success':
                            addressDistributionStatus[userQuotable.id],
                        }"
                      >
                        de {{ userQuotable.quantity }}
                        -
                        {{
                          userQuotable.quotable.redeemable_type ===
                          "App\\Models\\Product"
                            ? userQuotable.quotable.display_name
                            : userQuotable.quotable.redeemable.product
                                .storefront_redeemables[0].display_name +
                              " - " +
                              userQuotable.quotable.redeemable.value
                        }}
                      </div>
                      <div
                        class="uk-text-success"
                        uk-icon="check"
                        v-if="addressDistributionStatus[userQuotable.id]"
                      ></div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div
            class="uk-text-center"
            v-if="Object.keys(model.addresses).length === 0"
          >
            Aún no has agregado una dirección a tu cuenta
          </div>

          <div class="uk-text-center uk-margin-top">
            <button
              test="complete-checkout-button"
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="
                invalid ||
                isLoading ||
                !Object.values(addressDistributionStatus).every((x) => x)
              "
              @click="redeemProducts"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else> Completar compra </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserQuotables from "@/views/Utils/UserQuotables";
export default {
  name: "Checkout",

  components: {
    UserQuotables,
  },

  data() {
    return {
      multipleShippingAddresses: false,
      userQuotables: [],
      isLoading: false,
      addresses: [],
      mainAddressId: null,
      isLoadingShippingQuote: false,
      model: {
        addresses: {},
      },
      shippingLabels: [],
    };
  },

  computed: {
    ...mapGetters(["pointsName", "pointsIcon"]),
    addressDistributionStatus() {
      return this.userQuotables.reduce((accum, userQuotable) => {
        accum[userQuotable.id] =
          userQuotable.quantity ===
          Object.values(this.model.addresses)
            .filter((address) => address.selected)
            .reduce((accum, address) => {
              return accum + address.redeemables[userQuotable.id];
            }, 0);

        return accum;
      }, {});
    },
  },

  mounted() {
    this.getQuoteInfo();
  },

  methods: {
    ...mapActions(["pointsFetchBalance"]),
    redeemProducts() {
      this.isLoading = true;
      this.axios
        .post("/quotes/redeem", {
          addresses: Object.entries(this.model.addresses).reduce(
            (accum, entry) => {
              if (entry[1].selected) {
                accum[entry[0]] = entry[1].redeemables;
              }

              return accum;
            },
            {}
          ),
        })
        .then(({ data: { redeem_id } }) => {
          this.pointsFetchBalance();
          this.$router.push("/redeem/" + redeem_id);
        })
        .catch(() => {
          this.getQuoteInfo(false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getQuoteInfo(refetchAddresses = true) {
      this.isLoading = true;
      this.axios
        .get("/quotes?saved_for_later=0")
        .then(({ data: { userQuotables } }) => {
          this.userQuotables = userQuotables;
          if (refetchAddresses) {
            this.fetchAddresses();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getShippingQuote() {
      this.isLoadingShippingQuote = true;
      this.axios
        .post("store/shipping/quote", {
          destinies: Object.keys(this.model.addresses)
            .filter((addressId) => this.model.addresses[addressId].selected)
            .map((addressId) => ({
              address_id: addressId,
            })),
        })
        .then(({ data: { shippingLabels } }) => {
          this.shippingLabels = shippingLabels;
        })
        .finally(() => {
          this.isLoadingShippingQuote = false;
        });
    },
    fetchAddresses() {
      this.isLoadingAddresses = true;
      this.axios
        .get("/addresses")
        .then(({ data }) => {
          this.addresses = data.addresses;
          this.model.addresses = this.addresses.reduce(
            (accum, address, index) => {
              accum[address.id] = {
                selected: index === 0,
                redeemables: this.userQuotables.reduce(
                  (accum, userQuotable) => {
                    accum[userQuotable.id] = 0;

                    return accum;
                  },
                  {}
                ),
              };

              return accum;
            },
            {}
          );

          if (this.addresses.length) {
            this.mainAddressId = this.addresses[0].id;
          }
        })
        .finally(() => {
          this.isLoadingAddresses = false;
        });
    },
    updateAddressQuantities() {
      this.addresses.forEach((address) => {
        if (address.id === this.mainAddressId) {
          this.model.addresses[address.id].selected = true;
          this.userQuotables.forEach((userQuotable) => {
            this.model.addresses[address.id].redeemables[userQuotable.id] =
              userQuotable.quantity;
          });
        } else {
          this.model.addresses[address.id].selected = false;
          this.userQuotables.forEach((userQuotable) => {
            this.model.addresses[address.id].redeemables[userQuotable.id] = 0;
          });
        }
      });
    },
  },

  watch: {
    multipleShippingAddresses() {
      this.updateAddressQuantities();
    },
    mainAddressId() {
      this.updateAddressQuantities();
    },
    model: {
      deep: true,
      handler() {
        if (Object.keys(this.model.addresses).length) {
          this.getShippingQuote();
        }
      },
    },
  },
};
</script>